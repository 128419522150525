import { Container, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import CookieBar from "../components/Controls/CookieBar";
import Section from "../components/Controls/Section";
import Footer from '../components/Footer';
import HomepageHeader from "../components/HomepageHeader";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableHeader: {
            fontFamily: 'Spartan MB Bold',
            marginBottom: '1.5rem',
            fontSize: '1rem',
            [theme.breakpoints.only('xs')]: {
                fontSize: '0.875rem',
            },
        },
        tableItem: {
            marginBottom: '1rem',
            fontSize: '1rem',
            [theme.breakpoints.only('xs')]: {
                fontSize: '0.875rem',
            },
        },
        container: {
            minHeight: '100vh',
        },
    })
);

const CookiePolicy = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <HomepageHeader />
            <Container maxWidth='sm' className={classes.container}>
                <Section sectionTitle='Cookie Policy'>
                    <Typography>
                        We use cookies and similar tools across our websites to improve their performance and enhance your user experience. This policy explains how we do that.
                </Typography>
                </Section>
                <Section sectionTitle='What are cookies?'>
                    <Typography>
                        Cookies are small text files which a website may put on your computer or mobile device when you first visit a site or page. The cookie will help the website, or another website, to recognise your device the next time you visit. Web beacons or other similar files can also do the same thing. We use the term “cookies” in this policy to refer to all files that collect information in this way.
                </Typography>
                    <br />
                    <Typography>
                        There are many functions cookies serve. For example, they can help us to remember your username and preferences, allow you to quote on our system and analyse how well our website is performing.
                </Typography>
                    <br />
                    <Typography>
                        Certain cookies contain personal information – for example, if you click to “remember me” when logging in, a cookie will store your username. Most cookies won’t collect information that identifies you, and will instead collect more general information such as how users arrive at and use our websites, or a user’s general location.
                </Typography>
                </Section>
                <Section sectionTitle='The cookies we use are listed below:'>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.tableHeader}>
                                Name of Cookies
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.tableHeader}>
                                What these cookies do
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.tableItem}>
                                ASP.NET_SessionId
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.tableItem}>
                                These cookies allow you to progress through the members area of our website.
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.tableItem}>
                                Google Analytics - utma<br />
                                Google Analytics - utmb<br />
                                Google Analytics - utmc<br />
                                Google Analytics - utmz
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.tableItem}>
                                These cookies are used to collect information about how visitors use our site. We use the information to compile reports and to help us improve the site. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the site from and the pages they visited.
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.tableItem}>
                                WisePops
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.tableItem}>
                                WisePops is a tool to serve pop up messages. It uses a cookie to prevent the same message being shown more than once. We do not capture personal data in the pop ups.
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.tableItem}>
                                Hotjar - _hjid
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.tableItem}>
                                Hotjar cookie. This cookie is set when the customer first lands on a page with the Hotjar script. It is used to persist the random user ID, unique to that site on the browser. This ensures that behavior in subsequent visits to the same site will be attributed to the same user ID.
                        </Typography>
                        </Grid>
                    </Grid>
                </Section>
                <Section sectionTitle="More Information">
                    <Typography>
                        More detail on how businesses use cookies is available at <a href={"https://www.allaboutcookies.org"} target={'_blank'}>www.allaboutcookies.org</a>.
                </Typography>
                    <br />
                    <Typography>
                        If you have any queries regarding this Cookie Policy please get in touch with our Privacy Officer via the ‘Contact Us’ link at the top of this page.
                </Typography>
                    <br />
                    <Typography>
                        This document was last updated on 10th June 2019.
                </Typography>
                </Section>
            </Container>
            <Footer />
            <CookieBar />
        </Fragment>
    );
};

export default CookiePolicy;